@import '../../global.css';

.postWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1px
}

.postWrapper > .post {
    width: 100%;
}

.votes {
    background: var(--pop--accent);
    border-radius:  6px;
    display: flex;
    justify-content: left;
    align-items: right;
    flex-direction: column;
}

.votes > * {
    background: var(--pop);
    color: var(--dark);
    cursor: pointer;
    border: 1px solid var(--dark);
    border-radius:  6px;
    margin: none;
}

.votes > *:hover {
    background: var(--pop--accent);
    color: var(--pop);
}