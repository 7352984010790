@import '../../global.css';

.alert {
    z-index: 10;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: var(--accent);
    border: 5px solid red;
    border-radius: 10px;
    width: 80vw;
    height: 10vh;
    bottom:50%;
    left:10vw;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    font-variant: small-caps;
    font-weight: bolder;
    color: var(--dark);
    font-size: var(--medium-text);
    align-items: center;
}   

.alertText {
    z-index: 10;
}

.alertButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    z-index: 2;
}

.alertButtons > .button {
    width: 10vw;
}

.blur {
    background-color: gray;
    filter: blur(10px);
    opacity: .2;
    position: absolute; 
    bottom: var(--footer-height);
    left:0%;
    filter: blur(5px);
    z-index: 1;
    width: 100vw;
    height: calc(100vh - var(--header-height) - var(--footer-height));
}
