.header-navbar {
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: right;
    background-color: var(--background);
    border-bottom-right-radius: calc(var(--header-height) / 4);
}

.header-list {
    padding:0;
    height: 100%;
    width: 100%;
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: right;
    align-items: center;
    gap: .25vw;
}

.header-link {
    display: flex;
    height: 100%;
    width: auto;
    padding-right: 5px;
    align-items: center;
    justify-content: right;
    text-decoration: none;
    font-variant: small-caps;
    font-size: var(--small-text);
    color: var(--dark);
}

.header-list > a {
    text-decoration: none;
    color: var(--dark);
    font-variant: small-caps;
    font-size: var(--small-text);
}

.header-link:hover {
    background-color: var(--pop);
    background: linear-gradient(to top, var(--pop) 10%, var(--background) 11%, var(--background) 95%, var(--pop) 95%, var(--pop) 100%);
}

.header-link:hover:active {
    background-color: var(--pop);
    background: linear-gradient(to top, var(--pop) 10%, var(--background) 11%, var(--background) 91%, var(--pop) 95%, var(--pop) 100%);
}