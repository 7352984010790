@import '../../global.css';

.homeContainer {
    max-height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
}

.homeContainer > .posts {
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 2px;

}

.dailyPrompt {
}
