.logoutButton {
    background-color: var(--background);
    border: none;
    display: flex;
    height: 100%;
    width: auto;
    padding-right: 5px;
    align-items: center;
    justify-content: right;
    text-decoration: none;
    font-variant: small-caps;
    font-size: var(--small-text);

    color: var(--dark);
}

.logoutButton:hover {
    cursor: pointer;
    background-color: var(--pop);
    background: linear-gradient(to top, var(--pop) 10%, var(--background) 10%, var(--background) 95%, var(--pop) 95%, var(--pop) 100%);
}

.logoutButton:hover:active {
    background-color: var(--pop);
    background: linear-gradient(to top, var(--pop) 10%, var(--background) 11%, var(--background) 91%, var(--pop) 95%, var(--pop) 100%);
}