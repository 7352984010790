:root {

    --background: #F0ECE3;
    --background-accent:#d4cfc5;
    --accent: #DFD3C3;
    --pop: #C7B198;
    --pop-accent: #c6b8a8;
    --dark: #596E79;

    --background: #FEFBF6;
    --background-accent: #c2c0bc;
    --accent: #A6D1E6;
    --pop: #7F5283;
    --pop-accent:#a887ac;
    --dark: #3D3C42;

    /* --background: #F7ECDE;
    --background-accent:#E9DAC1;
    --accent:#c0ebe1;
    --pop: #54BAB9;
    --pop-accent:#9ED2C6;
    --dark: #3D3C42; */

    min-height: 100vh;
    max-height: 100vh;
    min-height: -webkit-fill-available;
    max-height: -webkit-fill-available; 
    --footer-height: 4vh;
    --header-height: 5vh;
    --page-height: calc(100% - var(--header-height) - var(--footer-height));
    color: var(--dark);
    background-color: var(--background);
    --small-text: clamp(.4em, .6em, .8em);
    --medium-text: clamp(.8em, 1em, 1.2em);
    --large-text: clamp(1em, 1.2em, 1.4em);

}

.button {
    border: none;
    border-radius: 6px;
    font-variant: small-caps;
    position: relative;
    top: -1px;
    left: -1px;
    color: var(--dark);
    box-shadow: 2px 2px 0px 0px var(--dark);
    background-color: var(--pop-accent);
    font-size: var(--small-text);
}

.button:hover {
    cursor: pointer;
} 

.button:active {
    top: 0px;
    left: 0px;
    box-shadow: 0px 0px 0px 0px;
    cursor: pointer;
    filter: brightness(110%);
}

textarea {
    border: none;
}

textarea:focus {
    outline: 2px solid var(--pop);
}

input:focus {
    outline: 2px solid var(--pop);
}

::-webkit-scrollbar {
    width: 0px
}

  

