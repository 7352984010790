@import '../../global.css';

.navbar-list {
    list-style: none;
    height: 100%;
    width: 100%;
    background-color: var(--background-accent);
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    box-shadow: 10px 10px 10px 10px var(--dark);
}

.navbar-list .active {
    font-size: var(--medium-text);
    font-weight: bolder;
    background-color: var(--pop)
}

.nav-link {
    background-color: var(--pop-accent);
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: var(--medium-text);
    color: var(--dark);
}

.nav-link:hover {
    filter: brightness(.8);
}
