@import '../../global.css';

.navbarContainer {
    min-width: 100vw;
    width: 100vw;
    max-width: 100vw;
    min-height: var(--footer-height);
    height: var(--footer-height);
    max-height: var(--footer-height);
    overflow: hidden;
    box-shadow: 10px 10px 10px 10px var(--dark);
}

