@import '../../global.css';

.headerContainer {
    min-width: 100vw;
    width: 100vw;
    max-width: 100vw;
    min-height: var(--header-height);
    height: var(--header-height);
    max-height: var(--header-height);
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px var(--dark);
    display: grid;
    grid-template-columns: minmax(30vw, 40vw) auto auto;
}
