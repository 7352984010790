@import '../../global.css';

.appContainer {
    grid-template-rows:  var(--header-height) var(--page-height) var(--footer-height);
    min-width: 100vw;
    width: 100vw;
    max-width: 100vw;
    min-height: -webkit-fill-available;
    height: 100px;
    overflow: hidden;
    position: absolute;
}