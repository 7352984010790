
.header-text {
    padding-left: 5px;
    width: 100%;
    height: inherit;
    min-height: inherit;
    display: flex;
    font-variant: small-caps;
    color: var(--dark);
    align-items: center;
    justify-content: left;
    font-size: var(--large-text);

    font-weight: bolder;
    overflow: hidden;

}

.header-text:hover {
    opacity: 1;
    cursor: pointer;
    background-image:  linear-gradient(to top, var(--pop) 10%, var(--background) 11%, var(--background) 95%, var(--pop) 95%, var(--pop) 100%);
}
