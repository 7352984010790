@import '../../global.css';

.writeInputContainer {
    background-color: var(--background-accent);
    height: 100%;
    display:flex;
    flex-direction:column;
    box-shadow: 10px 10px 10px 10px var(--dark);
    color: var(--dark);
}

.writeInputHeader > .button {
    font-size: var(--medium-text);
    border-radius: 0px;
}

.writeInputHeader {
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.writeInputTitle { 
    color: var(--dark);
    margin:0; 
    padding:0;
    padding-left: 6px;
    border:0;
    width: 80vw;
    outline: 0px;
}

.writeInputTitle:active {
    outline: 0px;
}

.writeInputButton {
    width: 20vw;
}


.writeInputText {
    color: var(--dark);
    height: 80%;
    word-break: break-word;
    overflow-wrap: break-word;
    margin: 0;
    resize: none;
    padding: 0;
    padding-left: 6px;
    padding-right: 6px;
}
