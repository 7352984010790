@import "../../global.css";

.writeContainer {
    min-width: 100vw;
    width: 100vw;
    max-width: 100vw;
    min-height: 100%;
    height: 100%;
    max-height: 100%;
    display: grid;
    grid-template-rows: 5% 75% 20%;
    flex-direction: column;
    align-items: left;
}

.writeContainer.down {
    grid-template-rows: 5% 95%;
}

.writeTitle {
    min-width: 100vw;
    width: 100vw;
    max-width: 100vw;
}

.writePosts {
    min-width: 100vw;
    width: 100vw;
    max-width: 100vw;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    height: 100%;
    transition: all 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940); 
}

.writeContainer.down { 
}

.writeInput {
    min-width: 100vw;
    width: 100vw;
    max-width: 100vw;
    transition: transform 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940); 
    height: 100%;
}
 
.writeInput.up {
    transform: translateY(0%);

}

.writeInput.down {
    transform: translateY(100%);
}