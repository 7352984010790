@import '../../global.css';

.bodyContainer {
    min-width: 100vw;
    width: 100vw;
    max-width: 100vw;
    min-height: var(--page-height);
    height: var(--page-height);
    max-height: var(--page-height);
    overflow: hidden;
}