@import '../../global.css';



.postContainer {
    color: var(--dark);
    border: 1px solid var(--background-accent);
    border-radius: 6px;
    background-color: var(--background-accent);
    margin-bottom: 6px;
    width: 100%;
    transition: transform 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940); 

}

.postContainer.left {
    transform: translateX(-5%);
    
}

.postContainer.right {
    transform: translateX(5%);
}

.postContainer.noOpts {
    width: 90vw;
    margin-bottom: 0px;

}

.postContainer.istrue {
    border: 2px solid red;
    filter: brightness(.8);
}

.postHeader {
    min-height: 2em;
    display: flex;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    padding-left: 6px;
    padding-right: 6px;
    font-size: var(--medium-text);
    border-radius: 6px;
    text-align: center;
    overflow: wrap;
    word-break: break-all;
    background-color: var(--accent);
}

.postHeader.noOpts {
    grid-template-columns: 1fr 1fr;
}

.postHeader > .postInfo {
    font-size: var(--small-text);
}

.postContent{
    font-size: var(--small-text);
    padding: 6px;
    border-radius: 6px;
    overflow: wrap;
    word-break:break-all;
    background-color: var(--background);
}

.postOptions  {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: var(--dark);
    border-radius: 6px;
    background-color: var(--accent);
    gap: 4px;
} 

.postOptions > button {
    /* position: relative; */
    top: -1px;
    height: 1.5em;
    text-align: justify;
}


